export const refreshValuesOfUrlParam = (paramName: string, paramValues: string[]): void => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete(paramName);
  paramValues.forEach(value => urlParams.append(paramName, value));

  const params = urlParams.toString();
  const newUrl = window.location.origin + window.location.pathname + (params.length > 0 ? "?" + params : "");
  window.history.pushState({}, null, newUrl);
};

export const getValuesFromUrlParam = (paramName: string): string[] => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.getAll(paramName);
};
