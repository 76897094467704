import { useState } from "react";
import { thematicOptions, toggleThematicHandler } from "../../utils/thematics";

interface Props {
  coloredThematics: boolean;
  filter: any;
  filterArgs: any;

  updateFilterArgs: (filterKey: string, newValues: any) => void;
}

const ThematicFilter: React.FC<Props> = ({ coloredThematics, filter, filterArgs, updateFilterArgs }) => {
  const [toggledThematicIds, setToggledThematicIds] = useState([]);
  const availableThematics = filter.metadata.possible_values;

  if (availableThematics.length < 1) return null;

  const toggle = (value): void => {
    const { thematics } = filterArgs;
    let newValues = [];
    if (!thematics) {
      newValues = [value];
    } else if (thematics.includes(value)) {
      newValues = [ ...thematics.filter(v => v !== value) ];
    } else {
      newValues = [ ...thematics, value ];
    }
    updateFilterArgs("thematics", newValues);
  };

  const renderThematicOption = (option): any => {
    const { thematics } = filterArgs;
    const toggled = toggledThematicIds.includes(option.value);
    const checked = thematics && thematics.includes(option.value);

    return <>
      <label htmlFor={`thematic-${option.value}`} className="form-label" style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}>
        <input
          type="checkbox"
          id={`thematic-${option.value}`}
          className="checkbox"
          checked={checked || false}
          style={{ marginRight: "5px" }}
          onChange={(): void => toggle(option.value)} />
        <span
          className="label"
          style={{ backgroundColor: option.color, margin: "3px 5px 3px 0" }}>
          {option.label}
        </span>
      </label>
      {option.hasChildren &&
        <button
          className="btn btn-link btn-xs"
          onClick={toggleThematicHandler(availableThematics, option.value, toggledThematicIds, setToggledThematicIds)} >
          <i className={`fa-regular fa-chevron-${toggled ? "up" : "down"}`}></i>
        </button>
      }
    </>;
  };

  return <div className="search-filter">
    {filter.labelDisplayed && filter.label?.length && <label style={{ paddingBottom: "10px" }}>{filter.label}</label>}
    <div style={{ display: "flex", flexDirection: "column" }}>
      {thematicOptions(availableThematics, coloredThematics, toggledThematicIds).map((option) => {
        return <div
          key={option.value}
          style={{
            display: option.show ? "flex" : "none",
            marginLeft: option.depthLevel * 15 }}>
          {renderThematicOption(option)}
        </div>;
      })}
    </div>
  </div>;
};

export default ThematicFilter;
