import { gql } from "@apollo/client";

const CORE_PROGRAM_FIELDS = gql`
  fragment CommonProgramFields on Program {
    navigationByDateEnabled
    groupSessionsByStartingDates
    textSearchEnabled
    nbLinesForDescription
    noResultsLabel
    coloredThematics
    coloredSessionTypes
    searchBarPosition
    sessionsDateDisplayed
    sessionsTimeDisplayed
    sessionsLocationDisplayed
    sessionsAttendanceTypeDisplayed
    sessionsTypeDisplayed
    sessionsThematicsDisplayed
    sessionsDescriptionDisplayed
    sessionsThematicsDisplayLevel
    speakersInformationDisplayed
    speakersLabel
    speakersLabelDisplayed
    speakersIllustrationDisplayed
    speakersNameDisplayed
    speakersCompanyNameDisplayed
    speakersPositionDisplayed
    exhibitorsInformationDisplayed
    exhibitorsLabel
    exhibitorsLabelDisplayed
    exhibitorsIllustrationDisplayed
    exhibitorsNameDisplayed
    exhibitorsCompanyNameDisplayed
    exhibitorsPositionDisplayed
    moderatorsInformationDisplayed
    moderatorsLabel
    moderatorsLabelDisplayed
    moderatorsIllustrationDisplayed
    moderatorsNameDisplayed
    moderatorsCompanyNameDisplayed
    moderatorsPositionDisplayed
    nbSessionsPerLine
    sessionsDays {
      value
      label
    }
    timeZoneLabel
    filters {
      rank
      type
      label
      labelDisplayed
      metadata
      dateSearchEnabled
      timeSearchEnabled
      timeSearchLabel
      timeSearchLabelDisplayed
      traitKey
    }
    displayOptionsRanking
    sessions(search: $search, filterArgs: $filterArgs, after: $cursor) {
      edges {
        node {
          id
          displayName
          startDate
          endDate
          description
          remainingSlots
          capacityReached
          location
          format
          sessionType {
            id
            name
            color
          }
          registered
          inUpdatesRequiringCheckout
          inGuestCategoryAccesspoints
          paidByGuest
          price
          currency
          liveOver
          hasReplay
          liveOpenAt
          minutesBeforeLiveAccess
          guestHasRole
          guestCanAccessLive
          traits {
            key
            value
            label
            labelDisplayed
          }
          thematics {
            name
            color
            parentThematicId
            hasChildren
          }
          speakers {
            id
            name
            companyName
            position
            illustrationUrl
          }
          exhibitors {
            id
            name
            companyName
            position
            illustrationUrl
          }
          moderators {
            id
            name
            companyName
            position
            illustrationUrl
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const PUBLIC_PROGRAM_FILTERS_AND_SESSIONS_QUERY = gql`
  ${CORE_PROGRAM_FIELDS}
  query Program($eventId: ID!, $id: ID!, $search: String, $filterArgs: JSON, $cursor: String) {
    publicViewer(eventId: $eventId) {
      program(id: $id) {
        ...CommonProgramFields
      }
      event {
        multilingual
      }
    }
  }
`;

export const PRIVATE_PROGRAM_FILTERS_AND_SESSIONS_QUERY = gql`
  ${CORE_PROGRAM_FIELDS}
  query Program($id: ID!, $search: String, $filterArgs: JSON, $cursor: String) {
    viewer {
      program(id: $id) {
        ...CommonProgramFields
      }
      event {
        multilingual
      }
    }
  }
`;
