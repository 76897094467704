import { MouseEvent } from "react";

interface Props {
  on: boolean;
  label: string;
  value: string;

  toggle: (e: MouseEvent<HTMLButtonElement>) => void
}

const ToggleButton: React.FC<Props> = ({ label, value, on, toggle }) => {
  const classBtn = on ? "active" : "ghost";
  return <button name={value.toString()} onClick={toggle} className={"checkbox button button-small " + classBtn}>
    {label}
  </button>;
};

export default ToggleButton;
