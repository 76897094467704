import { FilterProps } from "../types/Program";
import ToggleButton from "./ToggleButton.react";

const BookmarksOnlyFilter: React.FC<FilterProps> = ({ filter, filterArgs, updateFilterArgs }) => {
  if (filter.metadata.possible_values.length === 0) return null;

  const toggle = (): void => {
    const { bookmarks_only } = filterArgs;
    if (!bookmarks_only) {
      updateFilterArgs("bookmarks_only", true);
    } else {
      updateFilterArgs("bookmarks_only", null);
    }
  };

  return <div className="search-filter">
    {filter.labelDisplayed && filter.label?.length && <label style={{ paddingBottom: "10px" }}>{filter.label}</label>}
    <div>
      <ToggleButton
        label={I18n.t("react.programs.bookmarks_only_filter.title")}
        value={filter.type}
        on={filterArgs.bookmarks_only}
        toggle={toggle}
      />
    </div>
  </div>;
};

export default BookmarksOnlyFilter;
