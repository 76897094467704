import moment, { Moment } from "moment";

// the server sends a date that looks like this: 2023-02-16T11:30:00.000+03:00
// If we use this date as is, moment will apply the user offset to this.
// We don't want moment to set any offset, this is the exact time (we display time in the event timezone)
// So we recreate a date in the user timezone that has the same time by removing the timezone indication
// at the end of the string (+03:00 or -07:00 ...)
export default function momentIgnoreTimezone(date: string): Moment {
  // If we are in UTC zone, we will not have an offset on 6 char but the letter Z
  const dateStrWithoutTimezone = date.endsWith("Z") ? date.slice(0, -1) : date.slice(0, -6);
  return moment(dateStrWithoutTimezone);
}
