import Modal from "react-modal";
import { ProgramSession } from "./types/Program";
import AddToMyCalendarButtons from "../themes/components/AddToMyCalendarButtons.react";
import { defaultModalStyles } from "../themes/css/Constants";

interface Props {
  isOpen: boolean;
  session: ProgramSession;
  onClose(): void;
}

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`front_office.react.programs.${key}`, options);
};

const AddToMyCalendarModal: React.FC<Props> = ({ isOpen, session, onClose }) => {
  return <Modal
    className="program-v2 add-to-my-calendar-modal modal-content text-center"
    isOpen={isOpen}
    onRequestClose={onClose}
    style={{ ...defaultModalStyles }}>
    <div className="modal-header">
      <button type="button" className="close" onClick={onClose}><span aria-hidden="true">&times;</span></button>
      <h4 className="modal-title">
        <i className="fa-regular fa-calendar-days" aria-hidden="true"></i> {session?.displayName}
      </h4>
    </div>
    <div className="modal-body">
      <span className="fa-stack fa-3x text-success">
        <i className="fa-solid fa-circle fa-stack-2x"></i>
        <i className="fa-regular fa-calendar-check fa-stack-1x fa-inverse"></i>
      </span>
      <h3 style={{ marginTop: "10px" }}>{i18n("registration_success")}</h3>
      <h4 style={{ margin: "20px 0 10px" }}>
        <i className="fa-regular fa-calendar-plus"></i> {I18n.t("add_to_my_calendar")}
      </h4>
      <AddToMyCalendarButtons target={session} subject={session?.displayName}/>
    </div>
  </Modal>;
};

export default AddToMyCalendarModal;
