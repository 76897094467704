import moment from "moment";
import momentIgnoreTimezone from "../utils/momentIgnoreTimezone";

interface Props {
  startDate: string,
  endDate: string,
  timeZoneLabel: string,
  className?: string;
  ignoreTimezoneInDateParsing?: boolean
}

const LocalizedTimeRangeLabel: React.FC<Props> = ({
  startDate,
  endDate,
  timeZoneLabel,
  className,
  ignoreTimezoneInDateParsing
}) => {
  const dateParser = ignoreTimezoneInDateParsing ? momentIgnoreTimezone : moment;

  const startTime = dateParser(startDate).format("LT");
  const endTime = dateParser(endDate).format("LT");
  const timeZone = timeZoneLabel.length > 0 ? ` - ${timeZoneLabel}` : "";

  return <span className={className}><i className="fa-regular fa-clock"></i> {startTime} - {endTime}{timeZone}</span>;
};

export default LocalizedTimeRangeLabel;
