import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { GuestBasicInformationType } from "../types/GuestBasicInformation";

interface Props {
  sessionLivePagePath: string;
  canAccessLive: boolean;
  guestId: string;
  sessionId: string;
  liveOver: boolean;
  hasReplay: boolean;
  speakers: GuestBasicInformationType[];
  liveOpenAt: string;
  minutesBeforeLiveAccess?: number;
  extraClasses?: string;
  extraClassesLabel?: string;
  customButtonLabel?: string;
  customTooltipLabel?: string;
}

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`front_office.react.live_access_button.${key}`, options);
};

const secondsUntilDate = (date): number => {
  return (Date.parse(date) - Date.now()) / 1000;
};

const LiveAccessButton: React.FC<Props> = ({
  sessionLivePagePath,
  canAccessLive,
  guestId,
  sessionId,
  liveOver,
  hasReplay,
  speakers,
  liveOpenAt,
  minutesBeforeLiveAccess,
  extraClasses = "",
  extraClassesLabel = "",
  customButtonLabel = "",
  customTooltipLabel = "",
}) => {
  if (!sessionLivePagePath || !canAccessLive) return null;
  if (liveOver && !hasReplay) return null;

  const [livePageOpened, setLivePageOpened] = useState(secondsUntilDate(liveOpenAt) < 0);

  const pathToLive = (): string => {
    return "/" + sessionLivePagePath.replace("__id__", sessionId);
  };

  const renderLinkToReplay = () : JSX.Element => {
    return <a href={pathToLive()} className="btn btn-primary btn-sm">
      <i className="fa-brands fa-youtube mr-5" aria-hidden="true" /> {i18n("replay")}
    </a>;
  };

  if (liveOver && hasReplay) return renderLinkToReplay();

  const livePageAccessible = (): boolean => {
    const isSpeaker = speakers.map(s => s.id).includes(guestId);
    return isSpeaker || livePageOpened;
  };

  const renderLinkToLive = (onMouseOver: (e: React.MouseEvent<HTMLElement>) => void) : JSX.Element => {
    return <a
      href={livePageAccessible() ? pathToLive() : null}
      className={`btn btn-danger btn-sm ${extraClasses}`}
      onMouseOver={onMouseOver}>
      <i className="fa-brands fa-youtube mr-5" aria-hidden="true" /> <span className={extraClassesLabel}>{ customButtonLabel || i18n("access_live")}</span>
    </a>;
  };

  const onMouseOver = (): void => {
    if (secondsUntilDate(liveOpenAt) < 0) { // has already begun
      setLivePageOpened(true);
    }
  };

  if (livePageAccessible()) return renderLinkToLive(null);

  const accessibleTooltip = <Tooltip id="tooltip">{customTooltipLabel || i18n("live_open_at", { minutes_before_live_access: minutesBeforeLiveAccess })}</Tooltip>;

  return <OverlayTrigger placement="top" overlay={accessibleTooltip}>
    {renderLinkToLive(onMouseOver)}
  </OverlayTrigger>;
};

export default LiveAccessButton;
