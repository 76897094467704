import { ApolloProvider, ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { apolloClient } from "../themes/utils/apolloClient";

export interface ApolloProviderProps {
  graphQLToken: string;
  eventId?: string;
}

export interface ApolloWrappedComponentProps {
  client: ApolloClient<NormalizedCacheObject>;
}

function withApolloProvider<P extends ApolloProviderProps>(WrappedComponent: React.FC<P>): React.FC<P> {
  const ConnectToGraphql: React.FC<P> = (props) => {
    const { graphQLToken, eventId } = props;
    const client = apolloClient(graphQLToken, eventId);

    return <ApolloProvider client={client}>
      <WrappedComponent {...props} client={client} />
    </ApolloProvider>;
  };
  return ConnectToGraphql;
}
export default withApolloProvider;
