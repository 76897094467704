import "../require-babel-polyfill.js";
import Modal from "react-modal";
import ReactDOM from "react-dom";
import moment from "moment";
import Program from "../program/Program.react";

const elements = Array.from(document.getElementsByClassName("theme-react-anchor-program"));

(elements || []).forEach(element => {
  Modal.setAppElement(element);
  const {
    locale,
    guest_id,
    secret,
    guest_status,
    guest_registration_form_url,
    guest_confirmation_page_url,
    event_id,
    token,
    id,
    is_preview,
    primary_color,
    session_info_page_path,
    session_live_page_path,
    enable_registration,
    register_button_label,
    display_remaining_slots,
    enable_unregistration,
    unregister_button_label,
    registered_button_label,
    charged_session_button_label,
    enable_unknown_guest_custom_button,
    button_label_unknown_visitor_registration,
    force_new_registration,
    new_registration_category_id,
    unknown_visitors_registration,
    button_label_force_new_registration,
    unknown_visitors_category_id,
    confirmation_page_known_visitors,
    button_label_confirmation_page_known_visitors,
    change_known_visitors_category,
    button_label_known_visitors,
    known_visitors_new_category_id,
    step_number
  } = JSON.parse(element.dataset.reactProps);

  const props = {
    locale,
    guestId: guest_id,
    secret,
    guestStatus: guest_status,
    guestRegistrationFormUrl: guest_registration_form_url,
    guestConfirmationPageUrl: guest_confirmation_page_url,
    eventId: event_id,
    programId: id,
    isPreview: is_preview,
    graphQLToken: token,
    primaryColor: primary_color,
    sessionInfoPath: session_info_page_path,
    sessionLivePagePath: session_live_page_path,
    enableRegistration: enable_registration,
    registerButtonLabel: register_button_label,
    displayRemainingSlots: display_remaining_slots,
    enableUnregistration: enable_unregistration,
    unregisterButtonLabel: unregister_button_label,
    registeredButtonLabel: registered_button_label,
    chargedSessionButtonLabel: charged_session_button_label,
    enableUnknownGuestCustomButton: enable_unknown_guest_custom_button,
    buttonLabelUnknownVisitorRegistration: button_label_unknown_visitor_registration,
    forceNewRegistration: force_new_registration,
    newRegistrationCategoryId: new_registration_category_id,
    unknownVisitorsRegistration: unknown_visitors_registration,
    buttonLabelForceNewRegistration: button_label_force_new_registration,
    unknownVisitorsCategoryId: unknown_visitors_category_id,
    confirmationPageKnownVisitors: confirmation_page_known_visitors,
    buttonLabelConfirmationPageKnownVisitors: button_label_confirmation_page_known_visitors,
    changeKnownVisitorsCategory: change_known_visitors_category,
    buttonLabelKnownVisitors: button_label_known_visitors,
    knownVisitorsNewCategoryId: known_visitors_new_category_id,
    stepNumber: step_number,
  };

  moment.locale(locale);
  I18n.locale = locale || "en";

  ReactDOM.render(<Program {...props} />, element);
});
