export const timeConvert = (number): string => {
  let hours: any = Math.floor(number / 60);
  let minutes: any = number - (hours * 60);

  if (minutes < 10) { minutes = `0${minutes}`; }
  if (hours < 10) { hours = `0${hours}`; }

  return `${hours}:${minutes}`;
};

export const timeConvertReverse = (key, defaultValue, filterArgs): number => {
  if (!filterArgs[key]) return defaultValue;

  const [hours, minutes] = filterArgs[key].split(":").map(value => parseInt(value));

  return hours * 60 + minutes;
};

export const renderTime = (number, locale): string => {
  let period = "";
  let hours: any = Math.floor(number / 60);
  let minutes: any = number - (hours * 60);

  if (minutes < 10) { minutes = `0${minutes}`; }
  if (hours < 10) { hours = `0${hours}`; }

  if (locale === "en") {
    period = hours >= 12 ? " pm" : " am";

    if (hours === 0) {
      hours = 12;
    } else if (hours > 12) {
      hours -= 12;
    }
  }

  return `${hours}:${minutes}${period}`;
};
