import { useState, useLayoutEffect, useRef, CSSProperties } from "react";
import { removeTags } from "../../utils/StringUtils";
import "../css/line-clamp";

const LINE_CLAMP = {
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
};

const customStyle = (lineClamp = 1, baseStyle = {}): any => {
  LINE_CLAMP["WebkitLineClamp"] = lineClamp;
  return { ...LINE_CLAMP, ...baseStyle };
};

interface Props {
  content: string;
  lineClamp?: number;
  classDescription?: string;
  classBtn?: string;
  wrapperStyle?: CSSProperties;
}

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`front_office.react.programs.${key}`, options);
};

const ExpandableDescription: React.FC<Props> = ({
  content,
  lineClamp,
  classDescription,
  classBtn,
  wrapperStyle
}) => {
  const contentRef = useRef<HTMLInputElement>(null);
  const [isExpandable, setIsExpandable] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  useLayoutEffect(() => {
    if (contentRef.current) {
      const contentHeight = Math.round(contentRef.current.getBoundingClientRect().height);
      const contentHeightScroll = contentRef.current.scrollHeight;
      setIsExpandable(contentHeightScroll > contentHeight);
    }
  });

  if (removeTags(content).length === 0) return null;

  return <>
    <div ref={contentRef} dangerouslySetInnerHTML={{ __html: content }} className={`${classDescription} line-clamp-fix rte_text_content`} style={!isExpanded ? customStyle(lineClamp, wrapperStyle) : wrapperStyle} />
    {(isExpandable || isExpanded) && <button className={`${classBtn} btn-link`} onClick={(): void => setIsExpanded(!isExpanded)}>
      {isExpanded ? i18n("show_less") : i18n("show_more")}
    </button>}
  </>;
};

export default ExpandableDescription;
