import { useRef, useState, useEffect, CSSProperties } from "react";
import SlidingPane from "react-sliding-pane";
import { useLazyQuery } from "@apollo/client";
import withApolloProvider, { ApolloProviderProps } from "../utils/withApolloProvider.react";
import { PUBLIC_PROGRAM_FILTERS_AND_SESSIONS_QUERY, PRIVATE_PROGRAM_FILTERS_AND_SESSIONS_QUERY } from "./graphql/queries";
import Filters from "./Filters.react";
import FiltersSummary from "./FiltersSummary";
import Sessions from "./Sessions.react";
import FiltersPaneButton from "./FiltersPaneButton.react";
import SearchInput from "./SearchInput.react";
import DatesSelector from "./filters/DatesSelector.react";
import { ProgramSession, Program, SettingsProps } from "./types/Program";
import { mergeArrays } from "../utils/mergeArrays";
import { nbFiltersSelected } from "./utils/filtersCount";
import CloseIcon from "./icons/CloseIcon";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./style.scss";
import { GuestStatusRegistered, BOOLEAN_PROGRAM_FILTER_KEYS } from "../constants/Constants";
import { ReactAnchorTagProps } from "../types/ReactAnchorTag";

interface Props extends SettingsProps {
  programId: string;
  guestRegistrationFormUrl: string;
  guestConfirmationPageUrl: string;
}

const ProgramRoot: React.FC<Props & ReactAnchorTagProps & ApolloProviderProps> = ({
  eventId,
  programId,
  chargedSessionButtonLabel,
  displayRemainingSlots,
  enableRegistration,
  enableUnregistration,
  guestId,
  guestStatus,
  guestRegistrationFormUrl,
  guestConfirmationPageUrl,
  locale,
  primaryColor,
  registerButtonLabel,
  registeredButtonLabel,
  sessionInfoPath,
  sessionLivePagePath,
  unregisterButtonLabel,
  enableUnknownGuestCustomButton,
  buttonLabelUnknownVisitorRegistration,
  forceNewRegistration,
  newRegistrationCategoryId,
  unknownVisitorsRegistration,
  buttonLabelForceNewRegistration,
  unknownVisitorsCategoryId,
  confirmationPageKnownVisitors,
  buttonLabelConfirmationPageKnownVisitors,
  changeKnownVisitorsCategory,
  buttonLabelKnownVisitors,
  knownVisitorsNewCategoryId,
  stepNumber,
  isPreview,
}) => {
  const [currentProgram, setCurrentProgram] = useState<Program>(null);
  const [sessions, setSessions] = useState<ProgramSession[]>([]);
  const [error, setError] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [cursor, setCursor] = useState<string | null>(null);
  const [filterArgs, setFilterArgs] = useState({});
  const [search, setSearch] = useState<string | null>(null);
  const [isFiltersPaneOpen, setFilterPaneDisplay] = useState(false);
  const [searching, setSearching] = useState(false); // differentiate initial and filter search from load more search
  const [isSticky, setIsSticky] = useState(false);
  const [stickyFiltersOffset, setStickyFiltersOffset] = useState(0);
  const [stickyDatesOffset, setStickyDatesOffset] = useState(0);
  const [topSectionOffset, setTopSectionOffset] = useState(0);

  const filtersWrapper = useRef<HTMLDivElement>(null);
  const listenersSubscribed = useRef(false);

  const gplQuery = guestId && guestStatus === GuestStatusRegistered ? PRIVATE_PROGRAM_FILTERS_AND_SESSIONS_QUERY : PUBLIC_PROGRAM_FILTERS_AND_SESSIONS_QUERY;
  //If a guest is speaker and exhibitor for a session and illustration are different for speaker and exhibitor, GraphQL API returns different illustrationUrl for speaker and exhibitor.
  //But Apollo uses cache by default based on the id, here the guest id, which is the same for exhibitor and speaker.
  //So Apollo won't update exhibitor illustrationUrl if it first fetches speaker illustrationUrl. So we use fetchPolicy: "no-cache".
  const [fetchProgram, { loading, error: programError, data }] = useLazyQuery(gplQuery, { fetchPolicy: "no-cache" });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const initialFilterArgs = {};
    let initialSearch = null;

    urlParams.forEach((_, key: string) => {
      if (key === "search") {
        initialSearch = urlParams.get(key);
        setSearch(initialSearch);
      } else if (["start_time", "end_time"].includes(key)) {
        initialFilterArgs[key] = urlParams.get(key);
      } else if (BOOLEAN_PROGRAM_FILTER_KEYS.includes(key)) {
        initialFilterArgs[key] = urlParams.get(key) === "true" ? true : false;
      } else {
        initialFilterArgs[key] = urlParams.getAll(key);
      }
    });

    setFilterArgs(initialFilterArgs);
    searchSessions(initialSearch, initialFilterArgs);

    //Get section offset for scrollTo()
    const programSection = document.querySelectorAll<HTMLElement>("[data-section-type='program']")[0];
    const topSectionOffset = programSection.offsetTop || 0;
    setTopSectionOffset(topSectionOffset);

    setStickyOffset();
  }, []);

  const dataFetched = (): any => {
    if (!data) return null;

    return guestId && guestStatus === GuestStatusRegistered ? data.viewer : data.publicViewer;
  };

  useEffect(() => {
    if (!sessions || !currentProgram) return;
    if (listenersSubscribed.current) return;

    window.addEventListener("resize", (): void => setStickyOffset());
    window.addEventListener("scroll", (): void => {
      const noSessions = currentProgram && sessions.length === 0;
      setIsSticky(!noSessions && getFilterWrapperHeight() < window.innerHeight * 0.8);
      setStickyOffset();
    });

    listenersSubscribed.current = true;
  }, [sessions]);

  useEffect(() => {
    if (!data) return;
    if (!guestId && !data.publicViewer) return setError("Event not found");

    const { program } = dataFetched();
    if (!program) return setError("Program not found");

    setError(null);
    const fetchedSessions = program.sessions.edges.map(({ node }) => node) || [];
    const newSessions = searching ? fetchedSessions : mergeArrays(sessions, fetchedSessions, "id");
    setSessions(newSessions);
    if (searching) setSearching(false);

    const { hasNextPage, endCursor } = program.sessions.pageInfo;
    setHasNextPage(hasNextPage);
    setCursor(endCursor);

    setCurrentProgram(program);
  }, [data]);

  useEffect(() => {
    if (!programError) return;

    setError(programError.message);
  }, [programError]);

  const settingsConfiguration = (): SettingsProps => {
    return {
      chargedSessionButtonLabel,
      eventId,
      displayRemainingSlots,
      enableRegistration,
      enableUnregistration,
      guestId,
      primaryColor,
      registerButtonLabel,
      registeredButtonLabel,
      sessionInfoPath,
      sessionLivePagePath,
      unregisterButtonLabel,
      enableUnknownGuestCustomButton,
      buttonLabelUnknownVisitorRegistration,
      forceNewRegistration,
      newRegistrationCategoryId,
      unknownVisitorsRegistration,
      buttonLabelForceNewRegistration,
      unknownVisitorsCategoryId,
      confirmationPageKnownVisitors,
      buttonLabelConfirmationPageKnownVisitors,
      changeKnownVisitorsCategory,
      buttonLabelKnownVisitors,
      knownVisitorsNewCategoryId,
      stepNumber,
      isPreview,
    };
  };

  const positionElementStyle = (offset: number): CSSProperties => {
    return {
      position: isSticky ? "sticky" : "relative",
      top: isSticky ? offset : 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    };
  };

  const fetchData = (search = null, filterArgs = {}, cursor = null): void => {
    fetchProgram({
      variables: {
        eventId: eventId, id: programId, filterArgs, search, cursor
      }
    });
  };

  const fetchMore = (cursor): void => {
    if (!hasNextPage) return;
    if (loading) return;

    fetchData(search, filterArgs, cursor);
  };

  const searchSessions = (search: string, filterArgs: any): void => {
    setSearching(true);

    if (window.pageYOffset > topSectionOffset) {
      scrollTo(0, topSectionOffset);
    }

    fetchData(search, filterArgs);
  };

  const isSearchBarOntop = (): boolean => {
    const isMobile = window.innerWidth < 992;
    return currentProgram?.searchBarPosition === "top" || isMobile;
  };

  const getFilterWrapperHeight = (): number => {
    return filtersWrapper.current?.clientHeight || 0;
  };

  const getHeaderOffset = (): number => {
    const headerElement = document.getElementsByClassName("header_style_wrapper")[0];
    const headerHeight = headerElement?.clientHeight - 1 || 0;
    //Get position for the case: header not always visible
    const headerPosition = headerElement?.getBoundingClientRect().top || 0;

    return headerHeight + headerPosition;
  };

  const getStickyDatesOffset = (): number => {
    const stickyOffsetTopPosition = getFilterWrapperHeight() + getHeaderOffset();
    const stickyOffsetLeftPosition = getHeaderOffset();

    return isSearchBarOntop() ? stickyOffsetTopPosition : stickyOffsetLeftPosition;
  };

  const setStickyOffset = (): void => {
    setStickyFiltersOffset(getHeaderOffset);
    setStickyDatesOffset(getStickyDatesOffset);
  };

  const toggleFilterPane = (): void => {
    setFilterPaneDisplay(!isFiltersPaneOpen);
  };

  const renderSearchInput = (): JSX.Element => {
    if (!currentProgram?.textSearchEnabled) return null;

    return <SearchInput search={search} searchSessions={(search: string): void => {
      setSearch(search);
      searchSessions(search, filterArgs);
    }}/>;
  };

  const renderFiltersPaneButton = (): JSX.Element => {
    if (!currentProgram?.filters || currentProgram?.filters.length === 0) return null;

    return <FiltersPaneButton
      toggleFilterPane={toggleFilterPane}
      filterArgs={filterArgs}
      currentProgram={currentProgram}
    />;
  };

  const searchBarNeeded = (): boolean => {
    return currentProgram?.textSearchEnabled || currentProgram?.filters?.length > 0;
  };

  const renderSearchBar = (): JSX.Element => {
    if (!searchBarNeeded) return null;

    return <div className="form-group search-bar" style={{ position: "relative" }}>
      {currentProgram?.textSearchEnabled && renderSearchInput()}
      {isSearchBarOntop() && renderFiltersPaneButton()}
    </div>;
  };

  const renderFilters = (): JSX.Element => {
    return <Filters
      filters={currentProgram?.filters || []}
      locale={locale}
      filterArgs={filterArgs}
      coloredThematics={currentProgram?.coloredThematics}
      navigationByDateEnabled={currentProgram?.navigationByDateEnabled}
      searchSessions={(filterArgs: any): void => {
        setFilterArgs(filterArgs);
        searchSessions(search, filterArgs);
      }}
    />;
  };

  const renderFiltersSummary = (): JSX.Element => {
    if (!currentProgram) return null;
    if (nbFiltersSelected(currentProgram, filterArgs) === 0) return null;

    return <FiltersSummary
      key="FilterSummary"
      locale={locale}
      filters={currentProgram?.filters || []}
      filterArgs={filterArgs}
      navigationByDateEnabled={currentProgram?.navigationByDateEnabled}
      coloredThematics={currentProgram?.coloredThematics}
      coloredSessionTypes={currentProgram?.coloredSessionTypes}
      searchSessions={(filterArgs: any): void => {
        setFilterArgs(filterArgs);
        searchSessions(search, filterArgs);
      }}
    />;
  };

  const renderDatesSelector = (): JSX.Element => {
    if (!currentProgram?.navigationByDateEnabled) return null;

    return <DatesSelector
      key="DatesSelector"
      filterArgs={filterArgs}
      sessionsDays={currentProgram?.sessionsDays || []}
      inlineFilters={true}
      navigationByDateEnabled={currentProgram?.navigationByDateEnabled}
      searchSessions={(filterArgs: any): void => {
        setFilterArgs(filterArgs);
        searchSessions(search, filterArgs);
      }}
    />;
  };

  const renderFiltersSlidingPane = (): JSX.Element => {
    return <SlidingPane
      className="program-v2"
      key="FiltersSlidingPane"
      isOpen={isFiltersPaneOpen}
      title={I18n.t("front_office.react.programs.filters")}
      from="right"
      width="340px"
      onRequestClose={toggleFilterPane}
      closeIcon={<CloseIcon size="18px" margin="5px 0 0"/>}>
      {renderFilters()}
    </SlidingPane>;
  };

  const renderSessions = (): JSX.Element => {
    const data = dataFetched();
    return <div className={isSearchBarOntop() || !currentProgram || !searchBarNeeded() ? "col-xs-12" : "col-xs-9 mt-15"}>
      <Sessions
        sessions={sessions}
        program={currentProgram}
        guestStatus={guestStatus}
        settingsConfiguration={settingsConfiguration()}
        filterArgs={filterArgs}
        fetchMore={fetchMore}
        hasNextPage={hasNextPage}
        loading={loading}
        searching={searching}
        cursor={cursor}
        guestId={guestId}
        multilingual={data?.event?.multilingual}
        guestRegistrationFormUrl={guestRegistrationFormUrl}
        guestConfirmationPageUrl={guestConfirmationPageUrl}
        datesOffset={stickyDatesOffset}
        locale={locale}
      />
    </div>;
  };

  const renderFiltersWrapper = (): JSX.Element => {
    if (!currentProgram) return null;
    if (!searchBarNeeded && !currentProgram?.navigationByDateEnabled) return null;

    return <div
      key="FiltersWrapper"
      ref={filtersWrapper}
      className={`program-filter-wrapper card-border-radius ${!isSearchBarOntop() && "card-shadow mt-15"}`}
      style={isSearchBarOntop() ? positionElementStyle(stickyFiltersOffset) : null}>
      {renderSearchBar()}
      {isSearchBarOntop() && renderDatesSelector()}
      {renderFiltersSummary()}
      {!isSearchBarOntop() && renderFilters()}
    </div>;
  };

  const renderFiltersOnTop = (): JSX.Element | JSX.Element[] => {
    if (!isSearchBarOntop()) return renderDatesSelector();

    return [renderFiltersWrapper(), renderFiltersSlidingPane()];
  };

  const renderFiltersOnLeft = (): JSX.Element => {
    if (isSearchBarOntop() || !searchBarNeeded()) return null;

    return <div className="col-xs-3">
      <div className="col-xs-12" style={positionElementStyle(stickyFiltersOffset)}>
        {renderFiltersWrapper()}
      </div>
    </div>;
  };


  if (error) {
    console.error(error);

    return <div className="alert alert-danger">{I18n.t("generic_error_message")}</div>;
  }

  return <div className="program-v2 program-wrapper">
    {renderFiltersOnTop()}
    <div className="row-flex">
      {renderFiltersOnLeft()}
      {renderSessions()}
    </div>
  </div>;
};

export default withApolloProvider(ProgramRoot);
