import { useEffect } from "react";
import { ProgramFilter } from "./types/Program";
import { refreshValuesOfUrlParam } from "./utils/urlParams";
import { handleContainerGradients } from "./utils/handleContainerGradients";
import CloseIcon from "./icons/CloseIcon";
import { DEFAULT_THEMATICS_COLOR, DEFAULT_THEMATICS_COLOR_TEXT, SESSION_TYPE_COLOR, SESSION_TYPE_COLOR_TEXT, BOOLEAN_PROGRAM_FILTER_KEYS, PROGRAM_FILTER_KEY_FOR_FILTER_TYPE } from "../constants/Constants";
import { timeConvertReverse, renderTime } from "./utils/timeConvert";

interface Props {
  locale: string;
  filters: ProgramFilter[];
  filterArgs: any;
  navigationByDateEnabled: boolean;
  coloredThematics: boolean;
  coloredSessionTypes: boolean;

  searchSessions: (filterArgs: any) => void;
}

const FILTERS_SUMMARY_CONTAINER_CLASS = "filters-summary-container";

const FiltersSummary: React.FC<Props> = ({ locale, filters, filterArgs, navigationByDateEnabled, coloredThematics, coloredSessionTypes, searchSessions }) => {

  const defaultButtonSummaryStyle = {
    border: "none",
    borderRadius: "2px",
    lineHeight: 1.2,
    background: DEFAULT_THEMATICS_COLOR
  };

  useEffect(() => {
    const filtersSummaryContainer = document.getElementById(FILTERS_SUMMARY_CONTAINER_CLASS);
    handleContainerGradients(filtersSummaryContainer);
    filtersSummaryContainer.addEventListener("scroll", (): void => handleContainerGradients(filtersSummaryContainer));
  }, []);

  const sortedFilters = (): ProgramFilter[] => {
    return [...filters].sort((a, b) => a.rank - b.rank);
  };

  const updateFilterArgs = (filterKey: string, newValues: any): void => {
    refreshValuesOfUrlParam(filterKey, newValues);
    if (newValues === null || newValues.length === 0) {
      const newFilterArgs = { ...filterArgs };
      delete newFilterArgs[filterKey];
      searchSessions(newFilterArgs);
    } else {
      searchSessions({ ...filterArgs, [filterKey]: newValues });
    }
  };

  const onDeleteLabel = (key: string, value: any): void => {
    key === "time_filter" ? deleteTimeLabel() : deleteLabel(key, value);
  };

  const deleteLabel = (key: string, value: any): void => {
    let newValues;
    if (BOOLEAN_PROGRAM_FILTER_KEYS.includes(key)) {
      newValues = [];
    } else {
      newValues = [ ...filterArgs[key].filter(v => v !== value) ];
    }
    updateFilterArgs(key, newValues);
  };

  const deleteTimeLabel = (): void => {
    refreshValuesOfUrlParam("start_time", []);
    refreshValuesOfUrlParam("end_time", []);
    searchSessions({ ...filterArgs, start_time: "", end_time: "" });
  };

  const resetSearch = (): void => {
    const params = navigationByDateEnabled && filterArgs["dates"] ? "?dates=" + filterArgs["dates"] : "";
    const newUrl = window.location.origin + window.location.pathname + params;
    window.history.pushState({}, null, newUrl);

    if (navigationByDateEnabled && filterArgs["dates"]) {
      searchSessions({ dates: filterArgs["dates"] });
    } else {
      searchSessions({});
    }
  };

  const possibleSearchedValues = (filter: ProgramFilter, key: string): any => {
    const filterType = filter.type;
    const searchedValues = filterArgs[key];

    if (!searchedValues) return null;

    return filter.metadata.possible_values.filter((possibleValue) => {
      const value = filterType === "thematic" ? possibleValue._id : possibleValue.value;
      return searchedValues?.includes(value);
    });
  };

  const renderFilterSummary = (filter: ProgramFilter): JSX.Element => {
    const filterType = filter.type;

    if (filterType === "date_range" && navigationByDateEnabled) return null;

    // rework
    if (BOOLEAN_PROGRAM_FILTER_KEYS.includes(filterType) && filterArgs[filterType]) {
      return renderButtonSummary(filterType, filter.label, "true");
    }

    const key = filterType === "trait" ? filter.traitKey : PROGRAM_FILTER_KEY_FOR_FILTER_TYPE[filter.type];
    const searchedValues = possibleSearchedValues(filter, key);

    if (!searchedValues) return null;

    return searchedValues.map((searchedValue, index) => {
      let value = searchedValue.value;
      let label = searchedValue.label;
      let color = DEFAULT_THEMATICS_COLOR;
      let colorText = DEFAULT_THEMATICS_COLOR_TEXT;

      if (filterType === "thematic") {
        value = searchedValue._id;
        label = searchedValue.localized_name;
        color = coloredThematics ? searchedValue.color : DEFAULT_THEMATICS_COLOR;
      } else if (filterType === "session_type") {
        value = searchedValue.value;
        label = searchedValue.label;
        color = coloredSessionTypes ? searchedValue.color : DEFAULT_THEMATICS_COLOR;
      } else if (filterType === "attendance_type") {
        color = coloredThematics ? SESSION_TYPE_COLOR : DEFAULT_THEMATICS_COLOR;
        colorText = coloredThematics ? SESSION_TYPE_COLOR_TEXT : DEFAULT_THEMATICS_COLOR_TEXT;
      }

      const customStyle = {
        ...defaultButtonSummaryStyle,
        background: color,
        color: colorText
      };

      return renderButtonSummary(key, label, value, index, customStyle);
    });
  };

  const renderTimeFilterSummary = (): JSX.Element => {
    const { start_time, end_time } = filterArgs;

    if (!start_time && !end_time || start_time === "00:00" && end_time === "24:00") return null;

    const startTimeNumber = timeConvertReverse("start_time", start_time, filterArgs);
    const endTimeNumber = timeConvertReverse("end_time", end_time, filterArgs);
    const startTimeLabel = renderTime(startTimeNumber, locale);
    const endTimeLabel = renderTime(endTimeNumber, locale);
    const label = startTimeLabel + " - " + endTimeLabel;

    return renderButtonSummary("time_filter", label, null);
  };

  const renderButtonSummary = (key: string, label: string, value: any, index?: string, customStyle?: any): JSX.Element => {
    return <button
      key={index || key}
      name={key}
      onClick={(): void => onDeleteLabel(key, value)}
      className={"label label-default"}
      style={customStyle || defaultButtonSummaryStyle}>
      {label} <CloseIcon size="8px" margin="0 0 0 5px"/>
    </button>;
  };

  const renderResetSearchButton = (): JSX.Element => {
    if (!Object.keys(filterArgs).length) return null;
    if (navigationByDateEnabled && Object.keys(filterArgs).length === 1 && filterArgs["dates"]) return null;

    return <button
      className="btn btn-link label"
      style={{ fontSize: "14px", padding: "0 5px" }}
      onClick={resetSearch}>
      <i className="fa-regular fa-circle-xmark" aria-hidden="true"></i> {I18n.t("front_office.react.programs.clear_all")}
    </button>;
  };

  return <div className="filters-summary-wrapper search-filter">
    <div id={FILTERS_SUMMARY_CONTAINER_CLASS} className="gradient">
      <div className="display-flex flex-wrap">
        {sortedFilters().map((filter) => renderFilterSummary(filter))}
        {renderTimeFilterSummary()}
        {renderResetSearchButton()}
      </div>
    </div>
  </div>;
};

export default FiltersSummary;
