import { FilterProps } from "../types/Program";
import ToggleButton from "./ToggleButton.react";

const MissedSessionsFilter: React.FC<FilterProps> = ({ filter, filterArgs, updateFilterArgs }) => {
  if (filter.metadata.possible_values.length === 0) return null;

  const toggle = (): void => {
    const { missed_sessions } = filterArgs;
    if (!missed_sessions) {
      updateFilterArgs("missed_sessions", [true]);
    } else {
      updateFilterArgs("missed_sessions", []);
    }
  };

  return <div className="search-filter">
    {filter.labelDisplayed && filter.label?.length && <label style={{ paddingBottom: "10px" }}>{filter.label}</label>}
    <div>
      <ToggleButton
        label={I18n.t("react.programs.missed_sessions_filter.title")}
        value={filter.type}
        on={filterArgs.missed_sessions}
        toggle={toggle}
      />
    </div>
  </div>;
};

export default MissedSessionsFilter;
