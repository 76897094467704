import { useEffect } from "react";
import { SessionDay } from "../types/Program";
import { refreshValuesOfUrlParam } from "./../utils/urlParams";
import { handleContainerGradients } from "./../utils/handleContainerGradients";

interface Props {
  filterArgs: any;
  sessionsDays: SessionDay[];
  inlineFilters: boolean;
  navigationByDateEnabled: boolean;

  searchSessions: (filterArgs: any) => void;
}

const DatesSelector: React.FC<Props> = ({ sessionsDays, filterArgs, inlineFilters, navigationByDateEnabled, searchSessions }) => {
  const handleDatesContainerScroll = (datesContainer): any => {
    if (!navigationByDateEnabled) return null;

    const checkedBtn = document.getElementById("checked-button");
    const checkedBtnOffsetLeft = checkedBtn?.offsetLeft;
    const checkedBtnOffsetRight = checkedBtnOffsetLeft + checkedBtn?.offsetWidth;
    const containerScrollLeftPos = datesContainer?.scrollLeft;
    const containerOnStartPos = containerScrollLeftPos > checkedBtnOffsetRight;
    const containerOnEndPos = checkedBtnOffsetLeft > containerScrollLeftPos + datesContainer?.offsetWidth;

    if (containerOnEndPos || containerOnStartPos) {
      const previousButtonWidth = checkedBtn?.closest(".date-btn").previousElementSibling?.getBoundingClientRect().width || 0;
      datesContainer?.scrollTo({ left: checkedBtnOffsetLeft - previousButtonWidth });
    }
  };

  useEffect(() => {
    const datesContainer = document.getElementById("dates-container");
    handleDatesContainerScroll(datesContainer);
    if (navigationByDateEnabled) {
      handleContainerGradients(datesContainer);
      datesContainer.addEventListener("scroll", (): void => handleContainerGradients(datesContainer));
    }
  }, []);

  const updateFilterArgs = (filterKey: string, newValues: any): void => {
    refreshValuesOfUrlParam(filterKey, newValues);
    searchSessions({ ...filterArgs, [filterKey]: newValues });
  };

  const renderButton = (label: string, value: string[], checked: boolean): JSX.Element => {
    return <label
      id={checked && inlineFilters ? "checked-button" : ""}
      className={`button button-small ghost checkbox ${checked ? "active" : ""}`}
      style={{ marginRight: "5px" }}>
      <input
        type="radio"
        checked={checked || false}
        onInput={(): void => {
          const datesContainer = document.getElementById("dates-container");
          handleDatesContainerScroll(datesContainer);
          if (navigationByDateEnabled) handleContainerGradients(datesContainer);
        }}
        onChange={(): void => updateFilterArgs("dates", value) } />
      <span>{label}</span>
    </label>;
  };

  const renderDayButton = (day: SessionDay, index: number): JSX.Element => {
    const { dates } = filterArgs;
    let checked = dates && dates.includes(day.value);

    if (navigationByDateEnabled && !dates && index === 0) {
      checked = true;
    }

    return <div key={day.value} className="date-btn">{renderButton(day.label, [day.value], checked)}</div>;
  };

  const renderAllDatesButton = (): JSX.Element => {
    if (navigationByDateEnabled) return null;

    const { dates } = filterArgs;
    const checked = !dates || dates.length === 0;

    return renderButton(I18n.t("front_office.react.programs.all_dates"), [], checked);
  };

  return <div className="dates-wrapper">
    <div
      id={inlineFilters ? "dates-container" : ""}
      className={inlineFilters ? "gradient" : ""}
      style={inlineFilters ? { overflow: "auto" } : { paddingTop: "10px" }}>
      <div style={inlineFilters ? { display: "flex", flexDirection: "row" } : {}}>
        {renderAllDatesButton()}
        {sessionsDays.map((day, index) => {
          return renderDayButton(day, index);
        })}
      </div>
    </div>
  </div>;
};

export default DatesSelector;
