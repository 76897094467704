import { useEffect } from "react";

export default function useWindowEventListener(event: string, fn: ((e: CustomEvent) => void)): void {
  useEffect(() => {
    window.addEventListener(event, fn);
    return () => {
      window.removeEventListener(event, fn);
    };
  }, []);
}
