import { useEffect, useState } from "react";
import { refreshValuesOfUrlParam } from "./utils/urlParams";

interface Props {
  search: string;

  searchSessions: (search: string) => void;
}

let searchSessionsTimeout: number;

const SearchInput: React.FC<Props> = ({ search, searchSessions }) => {
  const [ query, setQuery ] = useState<string>(search);

  useEffect(() => {
    // prevents to call searchSessions after the copy of search's value into query state
    if (query === search && searchSessionsTimeout === undefined) return;
    clearTimeout(searchSessionsTimeout);

    searchSessionsTimeout = setTimeout(() => {
      searchSessions(query);
      refreshValuesOfUrlParam("search", query === null ? [] : [query]);
    }, 600);
  }, [query]);

  const handleInputChange = ({ target }): void => {
    setQuery(target.value);
  };

  return <>
    <span className="custom-addon"><i className="fa-regular fa-magnifying-glass" /></span>
    <input type="text" name="query" className="form-control" value={query || ""} onChange={handleInputChange} style={{ height: "45px" }} placeholder={I18n.t("front_office.react.programs.search_input_placeholder")} />
  </>;
};

export default SearchInput;
