import { useState } from "react";
import { refreshValuesOfUrlParam, getValuesFromUrlParam } from "./../utils/urlParams";
import { timeConvertReverse, renderTime, timeConvert } from "./../utils/timeConvert";

interface Props {
  locale: string;
  min: number;
  max: number;
  filterArgs: any;

  searchSessions: (filterArgs: any) => void;
}

const TimeRangeSlider: React.FC<Props> = ({ locale, min, max, filterArgs, searchSessions }) => {
  const [minVal, setMinVal] = useState(timeConvertReverse("start_time", min, filterArgs));
  const [maxVal, setMaxVal] = useState(timeConvertReverse("end_time", max, filterArgs));

  const updateFilterArgs = (): void => {
    const startTime = timeConvert(minVal);
    const endTime = timeConvert(maxVal);
    const currentStartTime = getValuesFromUrlParam("start_time")[0];
    const currentEndTime = getValuesFromUrlParam("end_time")[0];

    refreshValuesOfUrlParam("start_time", [startTime]);
    refreshValuesOfUrlParam("end_time", [endTime]);

    // no need to search sessions if start and end time did not change
    if (currentStartTime === startTime && currentEndTime === endTime) return;

    searchSessions({ ...filterArgs, start_time: startTime, end_time: endTime });
  };

  return <div className="multi-range-wrapper" style={{ paddingTop: "10px" }}>
    <div style={{ height: "11px" }}>
      <input
        type="range"
        min={min}
        max={max}
        step={5}
        value={minVal}
        onChange={(e): void => {
          setMinVal(Math.min(Number(e.target.value), maxVal - 1));
        }}
        onMouseUp={updateFilterArgs}
        className="thumb left"
      />
      <input
        type="range"
        min={min}
        max={max}
        step={5}
        value={maxVal}
        onChange={(e): void => {
          setMaxVal(Math.max(Number(e.target.value), minVal + 1));
        }}
        onMouseUp={updateFilterArgs}
        className="thumb right"
      />

      <div className="slider">
        <div className="track"></div>
      </div>
    </div>
    <div>
      {renderTime(minVal, locale)} - {renderTime(maxVal, locale)}
    </div>
  </div>;
};

export default TimeRangeSlider;
