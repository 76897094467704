
export interface SettingsProps {
  isPreview: boolean;
  enableUnknownGuestCustomButton: boolean;
  buttonLabelUnknownVisitorRegistration: string;
  forceNewRegistration: boolean;
  newRegistrationCategoryId: string;
  unknownVisitorsRegistration: boolean;
  buttonLabelForceNewRegistration: string;
  unknownVisitorsCategoryId: string;
  confirmationPageKnownVisitors: boolean
  buttonLabelConfirmationPageKnownVisitors: string;
  changeKnownVisitorsCategory: boolean;
  buttonLabelKnownVisitors: string;
  knownVisitorsNewCategoryId: string;
  stepNumber: number;
}

interface Props {
  settingsConfiguration: SettingsProps;
  locale: string;
  guestId: string;
  guestStatus: string;
  multilingual: boolean;
  guestRegistrationFormUrl: string;
  guestConfirmationPageUrl: string;
}

const NOT_COMPLETELY_REGISTERED_STATUSES = ["imported", "invited", "in_progress"];
const UNREGISTERED_STATUSES = ["imported", "invited", "in_progress", "declined", "cancelled"];

const GuestRegistrationButton: React.FC<Props> = ({
  settingsConfiguration,
  guestId,
  guestStatus,
  multilingual,
  guestRegistrationFormUrl,
  guestConfirmationPageUrl,
  locale
}) => {

  const confirmationOrKnownVisitorsButtonLabel = (): string => {
    const { confirmationPageKnownVisitors, buttonLabelConfirmationPageKnownVisitors, buttonLabelKnownVisitors } = settingsConfiguration;
    if (confirmationPageKnownVisitors) return buttonLabelConfirmationPageKnownVisitors;

    return buttonLabelKnownVisitors;
  };

  const knownOrUnknownVisitorsButtonLabel = (): string => {
    const { buttonLabelUnknownVisitorRegistration, buttonLabelKnownVisitors } = settingsConfiguration;
    if (!guestId || NOT_COMPLETELY_REGISTERED_STATUSES.includes(guestStatus)) return buttonLabelUnknownVisitorRegistration;

    return buttonLabelKnownVisitors;
  };

  const registeredGuest = (): boolean => {
    return guestId && !UNREGISTERED_STATUSES.includes(guestStatus);
  };

  const buttonLabel = (): string => {
    const { forceNewRegistration, buttonLabelForceNewRegistration, isPreview } = settingsConfiguration;
    if (forceNewRegistration) return buttonLabelForceNewRegistration;

    if ((registeredGuest() || isPreview)) return confirmationOrKnownVisitorsButtonLabel();

    return knownOrUnknownVisitorsButtonLabel();
  };

  const unknownVisitorsUrl = (): string => {
    const { unknownVisitorsRegistration, unknownVisitorsCategoryId } = settingsConfiguration;
    if (!unknownVisitorsRegistration) return "";

    return registrationFormUrl(unknownVisitorsCategoryId, false);
  };

  const registrationFormUrl = (categoryId: string, forceNew: boolean): string => {
    if (categoryId.length === 0) return ;

    const localePrefix = multilingual ? `/${locale}` : "";
    return `${localePrefix}/registration/${categoryId}?force_new_registration=${forceNew}`;
  };

  const guestShouldHaveRegistrationUrl = (): boolean => {
    const { confirmationPageKnownVisitors } = settingsConfiguration;
    return !registeredGuest() || (registeredGuest() && !confirmationPageKnownVisitors);
  };

  const guestShouldHaveConfirmationUrl = (): boolean => {
    const { confirmationPageKnownVisitors } = settingsConfiguration;
    return confirmationPageKnownVisitors && registeredGuest();
  };

  const guestUrl = (): string => {
    const { changeKnownVisitorsCategory, knownVisitorsNewCategoryId, stepNumber } = settingsConfiguration;
    if (guestShouldHaveRegistrationUrl()) {
      let baseUrl = guestRegistrationFormUrl;
      if (changeKnownVisitorsCategory && knownVisitorsNewCategoryId) {
        baseUrl += `&guest_category_id=${knownVisitorsNewCategoryId}`;
      }
      if (stepNumber) {
        baseUrl += `&step_number=${stepNumber}`;
      }
      return baseUrl;
    } else if (guestShouldHaveConfirmationUrl()) {
      return guestConfirmationPageUrl;
    }
    return "";
  };

  const buttonUrl = (): string => {
    const { forceNewRegistration, newRegistrationCategoryId } = settingsConfiguration;
    if (forceNewRegistration) {
      return registrationFormUrl(newRegistrationCategoryId, true);
    } else if (guestId) {
      return guestUrl();
    } else {
      return unknownVisitorsUrl();
    }
  };

  const displayable = (): boolean => {
    const { isPreview } = settingsConfiguration;
    return (buttonUrl()?.length > 0 || isPreview);
  };

  if (!displayable()) return null;

  return <div className="pull-right text-end mt-10">
    <a href={buttonUrl()} className={"btn btn-primary btn-sm"}>
      {buttonLabel()}
    </a>
  </div>;
};

export default GuestRegistrationButton;
