
interface Props {
  size: string;
  margin: string;
}

const CloseIcon: React.FC<Props> = ({ size, margin }) => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.9 22" style={{ width: size, padding: "0px", margin: margin, pointerEvents: "none" }}>
    <path d="M12.9 11l8-8c.6-.5.6-1.5 0-2-.5-.6-1.5-.6-2 0l-8 8-8-8C2.4.4 1.4.4.9 1c-.6.5-.6 1.5 0 2l8 8-8 8c-.6.5-.6 1.5 0 2 .5.6 1.5.6 2 0l8-8 8 8c.5.6 1.5.6 2 0 .6-.5.6-1.5 0-2l-8-8z" fillRule="evenodd" clipRule="evenodd" fill="currentColor"/>
  </svg>;
};

export default CloseIcon;
