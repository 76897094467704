export const handleContainerGradients = (datesContainer): any => {
  const containerScrollLeftPos = datesContainer?.scrollLeft;
  const containerOnStartPos = containerScrollLeftPos === 0;
  const containerOnEndPos = datesContainer?.scrollWidth <= containerScrollLeftPos + datesContainer?.offsetWidth + 5;

  if (containerOnStartPos) {
    datesContainer.classList = ("gradient right");
  } else if (containerOnEndPos) {
    datesContainer.classList = ("gradient left");
  } else if (!containerOnStartPos && !containerOnEndPos) {
    datesContainer.classList = ("gradient left right");
  }
};
