import { useRef, useState, MouseEvent, useContext } from "react";
import { ProgramSession, SettingsProps, PaymentInfo } from "./types/Program";
import { useMutation } from "@apollo/client";
import { TOGGLE_SESSION_REGISTRATION } from "./graphql/mutations";
import useWindowEventListener from "../utils/useWindowEventListener";
import { TimeConflictCheckerContext } from "./Sessions.react";

interface Props {
  session: ProgramSession;
  settingsConfiguration: SettingsProps;
  registered: boolean;
  capacityReached: boolean;
  updateRemainingSlots(delta: number): void;
  updateRegistered(registered: boolean): void;
  showAddToCalendarModal: () => void;
}

const SessionRegistrationButton: React.FC<Props> = ({ session, settingsConfiguration, registered, capacityReached, updateRemainingSlots, updateRegistered, showAddToCalendarModal }) => {
  const [errors, setErrors] = useState(null);
  const timeConflictChecker = useContext(TimeConflictCheckerContext);
  const timeConflictNode = useRef<HTMLElement>(null);

  useWindowEventListener("remove-cart-item", (e) => {
    if (e.detail.accesspointId === session.id) {
      updateRegistered(false);
    }
  });

  const { enableUnregistration, registerButtonLabel, registeredButtonLabel, unregisterButtonLabel } = settingsConfiguration;

  const buttonLabel = (): string => {
    if (registered) {
      if (!enableUnregistration || session.inGuestCategoryAccesspoints) { // or in guest category accesspoints
        return registeredButtonLabel;
      } else if (session.paidByGuest) {
        return I18n.t("paid");
      } else {
        return unregisterButtonLabel;
      }
    } else if (session.price === 0) {
      return registerButtonLabel;
    }

    return `${settingsConfiguration.chargedSessionButtonLabel} (${session.price} ${session.currency})`;
  };

  const buttonClasses = (): string => {
    if (!registered) {
      return "accesspoint-register";
    }

    if (!enableUnregistration || session.inGuestCategoryAccesspoints || session.paidByGuest) {
      return "accesspoint-registered disabled";
    }

    return "accesspoint-unregister";
  };

  const canCallMutation = (): boolean => {
    if (loading) return false;

    if (registered) {
      return enableUnregistration && !session.inGuestCategoryAccesspoints && !session.paidByGuest;
    }

    return !session.capacityReached;
  };

  const callMutation = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    toggleSessionRegistration({
      variables: {
        accesspointId: session.id,
        active: !registered
      }
    });

    if (!registered) showAddToCalendarModal();
  };

  const [toggleSessionRegistration, { loading }] = useMutation(TOGGLE_SESSION_REGISTRATION, {
    onCompleted: ({ toggleSessionRegistration }) => {
      if (toggleSessionRegistration.errors) {
        setErrors(toggleSessionRegistration.errors);
        return;
      }

      registered ? timeConflictChecker.current.removeEvent(timeConflictNode.current) : timeConflictChecker.current.addEvent(timeConflictNode.current);

      updateRegistered(!registered);
      updateRemainingSlots(registered ? 1 : -1);
      setErrors(null);
      dispatchPaymentInfo(toggleSessionRegistration.paymentInfo);
    }
  });

  const dispatchPaymentInfo = (paymentInfo: PaymentInfo): void => {
    if (session.price === 0) return;

    const event = new CustomEvent("toggle-session-registration-with-price", {
      detail: {
        cart_items: (paymentInfo.cartItems || []).reduce((acc, cartItem) => {
          acc.push({
            price_incl_vat: cartItem.priceInclVat,
            label: cartItem.label,
            accesspoint_id: cartItem.accesspointId,
            quantity: cartItem.quantity,
            is_checked_out: cartItem.isCheckedOut
          });
          return acc;
        }, []),
        payment_total_excl_taxes: paymentInfo.paymentTotalExclTaxes,
        payment_total_incl_taxes: paymentInfo.paymentTotalInclTaxes,
        currency: paymentInfo.currency,
        balance: paymentInfo.balance,
        current_cart_excl_taxes: paymentInfo.currentCartExclTaxes,
        current_cart_incl_taxes: paymentInfo.currentCartInclTaxes,
        discount_amount: paymentInfo.discountAmount,
        discount_amount_excl_taxes: paymentInfo.discountAmountExclTaxes,
        discount_percentage: paymentInfo.discountPercentage,
        display_discount_without_taxes: paymentInfo.displayDiscountWithoutTaxes,
        payment_merchant_type: paymentInfo.paymentMerchantType,
        is_multi_cart: paymentInfo.isMultiCart,
        prices_after_discount: paymentInfo.pricesAfterDiscount,
      }
    });
    document.dispatchEvent(event);
  };

  if (capacityReached && !registered) {
    return null;
  }

  return <div className="pull-right text-end mt-10">
    <span
      ref={timeConflictNode}
      style={{ display: "flex", alignItems: "center" }}
      data-start-date={session.startDate}
      data-end-date={session.endDate}
      data-name={session.displayName}
      data-accesspoint-id={session.id}
    >
      <a href="#" className={`btn btn-primary btn-sm ${buttonClasses()}`} onClick={canCallMutation() ? callMutation : null}>
        {buttonLabel()}
      </a>
      { errors && <div className="text text-danger mt-10">{ errors.map(error => <p>{error}</p>) }</div> }
    </span>
  </div>;
};

export default SessionRegistrationButton;
