import { ProgramFilter } from "../types/Program";
import DatesSelector from "./DatesSelector.react";
import TimeRangeSlider from "./TimeRangeSlider.react";

interface Props {
  locale: string;
  filter: ProgramFilter;
  filterArgs: any;
  navigationByDateEnabled: boolean;

  searchSessions: (filterArgs: any) => void;
}

const DateRangeFilter: React.FC<Props> = ({ locale, filter, filterArgs, navigationByDateEnabled, searchSessions }) => {
  return <>
    {filter.timeSearchEnabled && <div className="search-filter">
      {filter.timeSearchLabelDisplayed && filter.timeSearchLabel?.length && <label>{filter.timeSearchLabel}</label>}
      <TimeRangeSlider
        locale={locale}
        min={0}
        max={1440}
        filterArgs={filterArgs}
        searchSessions={searchSessions}
      />
    </div>}
    {filter.dateSearchEnabled && filter.metadata.possible_values.length > 1 && <div className="search-filter">
      {filter.labelDisplayed && filter.label?.length && <label>{filter.label}</label>}
      <DatesSelector
        sessionsDays={filter.metadata.possible_values || []}
        filterArgs={filterArgs}
        inlineFilters={false}
        navigationByDateEnabled={navigationByDateEnabled}
        searchSessions={searchSessions}
      />
    </div>}
  </>;
};

export default DateRangeFilter;
