import { FilterProps } from "../types/Program";
import ToggleButton from "./ToggleButton.react";

const TraitFilter: React.FC<FilterProps> = ({ filter, filterArgs, updateFilterArgs }) => {
  if (filter.metadata.possible_values.length === 0) return null;

  const toggle = ({ target }): void => {
    const traitValues = filterArgs[filter.traitKey];

    let newValues = [];
    if (!traitValues) {
      newValues = [target.name];
    } else if (traitValues.includes(target.name)) {
      newValues = [ ...traitValues.filter(v => v !== target.name) ];
    } else {
      newValues = [ ...traitValues, target.name ];
    }
    updateFilterArgs(filter.traitKey, newValues);
  };

  return <div className="search-filter">
    {filter.labelDisplayed && filter.label?.length && <label style={{ paddingBottom: "10px" }}>{filter.label}</label>}
    <div style={{ display: "flex", flexDirection: "column" }}>
      {filter.metadata.possible_values.map(({ value, label }, index) => {
        return <div key={`attendance-type-toggle-button-${index}`}>
          <ToggleButton
            label={label.toString()}
            value={value}
            on={filterArgs[filter.traitKey]?.includes(value)}
            toggle={toggle}
          />
        </div>;
      })}
    </div>
  </div>;
};

export default TraitFilter;
