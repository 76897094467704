import { FilterProps } from "../types/Program";
import ToggleButton from "./ToggleButton.react";

const SortByRecommendationFilter: React.FC<FilterProps> = ({ filter, filterArgs, updateFilterArgs }) => {
  if (filter.metadata.possible_values.length === 0) return null;

  const toggle = (): void => {
    const { sort_by_recommendation } = filterArgs;
    if (!sort_by_recommendation) {
      updateFilterArgs("sort_by_recommendation", true);
    } else {
      updateFilterArgs("sort_by_recommendation", null);
    }
  };

  return <div className="search-filter">
    {filter.labelDisplayed && filter.label?.length && <label style={{ paddingBottom: "10px" }}>{filter.label}</label>}
    <div>
      <ToggleButton
        label={I18n.t("react.programs.sort_by_recommendation_filter.title")}
        value={filter.type}
        on={filterArgs.sort_by_recommendation}
        toggle={toggle}
      />
    </div>
  </div>;
};

export default SortByRecommendationFilter;
