import { BOOLEAN_PROGRAM_FILTER_KEYS, PROGRAM_FILTER_KEY_FOR_FILTER_TYPE } from "../../constants/Constants";
import { Program } from "../types/Program";

const isTimeFilterSelected = (filterArgs): boolean => {
  const { start_time, end_time } = filterArgs;
  let timeFilterSelected = false;

  if (!start_time && !end_time) {
    timeFilterSelected = false;
  } else if (start_time !== "00:00" || end_time !== "24:00") {
    timeFilterSelected = true;
  }

  return timeFilterSelected;
};

export const nbFiltersSelected = (currentProgram: Program, filterArgs: any): number => {
  const { navigationByDateEnabled, filters } = currentProgram;

  const filterKeysCanHaveSeveralValuesExceptDates = filters.reduce((acc, { type, traitKey }) => {
    if (!BOOLEAN_PROGRAM_FILTER_KEYS.includes(type) && type !== "date_range") {
      acc.push(type === "trait" ? traitKey : PROGRAM_FILTER_KEY_FOR_FILTER_TYPE[type]);
    }
    return acc;
  }, []);

  return Object.entries(filterArgs).reduce((acc: number, [key, value]) => {
    if (BOOLEAN_PROGRAM_FILTER_KEYS.includes(key)) {
      acc ++;
    } else if (Array.isArray(value) && value.length > 0) {
      if (!navigationByDateEnabled && key === "dates") {
        acc ++;
      } else if (filterKeysCanHaveSeveralValuesExceptDates.includes(key)) {
        acc += value.length;
      }
    }

    return acc;
  }, Number(isTimeFilterSelected(filterArgs)));
};
