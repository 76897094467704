import { useState } from "react";
import LiveAccessButton from "../themes/components/LiveAccessButton.react";
import SessionRegistrationButton from "./SessionRegistrationButton.react";
import { ProgramSession, Program, SettingsProps, GuestRoleType } from "./types/Program";
import { GuestStatusRegistered, GuestStatusPendingRegistration, DEFAULT_THEMATICS_COLOR, SESSION_TYPE_COLOR, SESSION_TYPE_COLOR_TEXT } from "../constants/Constants";
import ExpandableDescription from "../themes/components/ExpandableDescription.react";
import LocalizedTimeRangeLabel from "../themes/components/LocalizedTimeRangeLabel.react";
import GuestRegistrationButton from "../themes/components/GuestRegistrationButton.react";
import momentIgnoreTimezone from "../themes/utils/momentIgnoreTimezone";
import Thematics from "../themes/components/Thematics.react";
import { ThematicsDisplayLevel } from "../themes/types/Thematic";

interface Props {
  lastSessionElementRef: any;
  session: ProgramSession;
  program: Program;
  guestStatus: string;
  settingsConfiguration: SettingsProps;
  guestId: string;
  multilingual: boolean;
  guestRegistrationFormUrl: string;
  guestConfirmationPageUrl: string;
  locale: string;
  showAddToCalendarModal: () => void;
}

// Classes with this prefix are used to make custom changes easier.
const PROGRAM_CLASS = "react-program-session";

const i18n = (key: string, options: any = {}): string => {
  return I18n.t(`front_office.react.programs.${key}`, options);
};

const SessionCard: React.FC<Props> = ({
  session,
  program,
  guestStatus,
  locale,
  settingsConfiguration,
  lastSessionElementRef,
  guestId,
  multilingual,
  guestRegistrationFormUrl,
  guestConfirmationPageUrl,
  showAddToCalendarModal,
}) => {
  const [registered, setRegistered] = useState(null);
  const [remainingSlots, setRemainingSlots] = useState(null);
  const [capacityReached, setCapacityReached] = useState(null);
  const coloredThematics = program?.coloredThematics;
  const coloredSessionTypes = program?.coloredSessionTypes;

  let sessionCardWidth;
  switch (program?.nbSessionsPerLine) {
  case 1:
    sessionCardWidth = "col-xs-12";
    break;
  case 2:
    sessionCardWidth = "col-md-6 col-xs-12";
    break;
  case 4:
    sessionCardWidth = "col-sm-6 col-md-3 col-xs-12";
    break;
  default:
    sessionCardWidth = "col-sm-6 col-md-4 col-xs-12";
  }

  const registeredToSession = (): boolean => {
    if (registered !== null) return registered; // the state of registration after mutation takes precedence

    return session.registered || session.inUpdatesRequiringCheckout;
  };

  const guestCanRegister = (): boolean => {
    return !session.guestHasRole && [GuestStatusRegistered, GuestStatusPendingRegistration].includes(guestStatus);
  };

  const canAccessLive = (): boolean => {
    if (registered === null) {
      return session.guestCanAccessLive;
    }

    return session.guestHasRole || (registered && session.price === 0);
  };

  const sessionRemainingSlots = (): number => {
    // the state of remainingSlots after mutation takes precedence
    return remainingSlots !== null ? remainingSlots : session.remainingSlots;
  };

  const sessionCapacityReached = (): boolean => {
    // the state of capacityReached after mutation takes precedence
    return capacityReached !== null ? capacityReached : session.capacityReached;
  };

  const updateRemainingSlots = (delta: number): void => {
    if (!settingsConfiguration.displayRemainingSlots) return;
    if (session.remainingSlots === -1) return;
    if (session.price > 0) return; // when session has a price, nb_reserved is not updated until payment

    const newRemainingSlots = sessionRemainingSlots() + delta;

    setRemainingSlots(newRemainingSlots);
    setCapacityReached(newRemainingSlots === 0);
  };

  const dateFormated = (date: string): string => {
    const day = momentIgnoreTimezone(date).format("ddd");
    const shortDateWithoutYear = momentIgnoreTimezone(date).format("LL").replace(",", "").slice(0, -4);

    return `${day}, ${shortDateWithoutYear}`;
  };

  const renderDateTime = (): JSX.Element => {
    const { sessionsDateDisplayed, sessionsTimeDisplayed, timeZoneLabel } = program;
    if (!sessionsDateDisplayed && !sessionsTimeDisplayed) return null;

    const { startDate, endDate } = session;
    return <div style={{ display: "flex", flexWrap: "wrap", marginBottom: "10px" }}>
      {sessionsDateDisplayed && <div className={`${PROGRAM_CLASS}-date date`} style={{ marginRight: "10px" }}><i className="fa-regular fa-calendar"></i> <span>{dateFormated(startDate)}</span></div>}
      {sessionsTimeDisplayed && <LocalizedTimeRangeLabel className={`${PROGRAM_CLASS}-time`} startDate={startDate} endDate={endDate} timeZoneLabel={timeZoneLabel} ignoreTimezoneInDateParsing={true}/>}
    </div>;
  };

  const renderSessionName = (): JSX.Element => {
    const { sessionInfoPath } = settingsConfiguration;
    const { id, displayName } = session;
    const url = sessionInfoPath.replace("__id__", id);

    return url ? <a href={url}><h5 className={`${PROGRAM_CLASS}-name`}>{displayName}</h5></a> : <h5 className={`${PROGRAM_CLASS}-name`}>{displayName}</h5>;
  };

  const renderLabel = (format, isDisplayed): JSX.Element => {
    if (!format || !isDisplayed) return null;

    return <span
      className={`${PROGRAM_CLASS}-attendance label label-default`}
      style={{
        background: coloredThematics ? SESSION_TYPE_COLOR : DEFAULT_THEMATICS_COLOR,
        color: coloredThematics ? SESSION_TYPE_COLOR_TEXT : "#FFFFFF" }}>
      {format}
    </span>;
  };

  const renderThematics = (): JSX.Element => {
    if (!program.sessionsThematicsDisplayed) return null;

    const { sessionsThematicsDisplayLevel } = program;
    return <Thematics
      classNames={`${PROGRAM_CLASS}-thematics`}
      style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
      thematics={session.thematics}
      thematicsDisplayLevel={sessionsThematicsDisplayLevel as ThematicsDisplayLevel}
      coloredThematics={coloredThematics}
    />;
  };

  const renderSessionType = (): JSX.Element => {
    const { sessionsTypeDisplayed } = program;
    if (!sessionsTypeDisplayed) return null;

    const { name, color } = session.sessionType;
    return <span
      className={`${PROGRAM_CLASS}-type label label-default`}
      style={{
        background: coloredSessionTypes ? color : DEFAULT_THEMATICS_COLOR,
        color: "#FFFFFF" }}>
      {name}
    </span>;
  };

  const renderLabels = (): JSX.Element => {
    const { sessionsAttendanceTypeDisplayed, sessionsTypeDisplayed, sessionsThematicsDisplayed } = program;

    if (!sessionsAttendanceTypeDisplayed && !sessionsTypeDisplayed && !sessionsThematicsDisplayed) return null;

    return <div style={{ display: "flex", flexDirection: "column", marginTop: "5px" }}>
      <div>
        {renderLabel(session.format, sessionsAttendanceTypeDisplayed)}
        {renderSessionType()}
      </div>
      {renderThematics()}
    </div>;
  };

  const renderDescription = () : JSX.Element => {
    if (!program.sessionsDescriptionDisplayed) return null;

    return <ExpandableDescription content={session.description} lineClamp={program.nbLinesForDescription} classDescription={`${PROGRAM_CLASS}-description`} classBtn={`${PROGRAM_CLASS}-description-btn`} wrapperStyle={{ paddingTop: "10px" }} />;
  };

  const renderLocation = () : JSX.Element => {
    const { location } = session;

    if (!program.sessionsLocationDisplayed || !location) return null;

    return <span className={`${PROGRAM_CLASS}-location`} style={{ paddingTop: "10px" }}><i className="fa-regular fa-location-dot"></i> {location}</span>;
  };

  const renderRemainingSlots = (): JSX.Element => {
    if (!settingsConfiguration.displayRemainingSlots) return null;

    if (sessionCapacityReached()) {
      return <div className="text text-danger mt-20"><i className="fa-regular fa-xmark"></i> {i18n("no_more_seats_available")}</div>;
    } else if (sessionRemainingSlots() > 0) {
      return <div className="text text-warning mt-20"><i className="fa-regular fa-triangle-exclamation"></i> {i18n("seats_remaining", { count: sessionRemainingSlots() })}</div>;
    }
  };

  const renderPositionCompany = (guest, isPositionDisplayed, isCompanyNameDisplayed): JSX.Element => {
    if (!isPositionDisplayed && !isCompanyNameDisplayed) return null;

    const isPosition = isPositionDisplayed && guest.position?.length > 0;
    const isCompanyName = isCompanyNameDisplayed && guest.companyName;

    return <div>
      {isPosition && <span className={`${PROGRAM_CLASS}-guest-position`}>{guest.position}</span>}
      {isCompanyName && isPosition && <span className={`${PROGRAM_CLASS}-guest-separator`}> - </span>}
      {isCompanyName && <span className={`${PROGRAM_CLASS}-guest-company`}>{guest.companyName}</span>}
    </div>;
  };

  const renderGuestIllustration = (illustrationUrl: string, guestRoleType: GuestRoleType): JSX.Element => {
    if (!program[`${guestRoleType}IllustrationDisplayed`]) return null;

    return <div
      className={`${PROGRAM_CLASS}-guest-avatar`}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginRight: "10px",
        height: "40px",
        width: "40px",
        borderRadius: "100%",
        overflow: "hidden",
        border: "1px solid #E0E0E0",
        flexShrink: 0
      }}>
      <img src={illustrationUrl} />
    </div>;
  };

  const renderGuestInformation = (guestRoleType: GuestRoleType): JSX.Element => {
    const guests = session[guestRoleType];
    if (!program[`${guestRoleType}InformationDisplayed`] || !guests || guests.length === 0) return null;

    const isNameDisplayed = program[`${guestRoleType}NameDisplayed`];
    const isPositionDisplayed = program[`${guestRoleType}PositionDisplayed`];
    const isCompanyNameDisplayed = program[`${guestRoleType}CompanyNameDisplayed`];
    const isClassWrapper = !isNameDisplayed && !isPositionDisplayed && !isCompanyNameDisplayed;

    return <div key={guestRoleType} className="mt-20">
      {program[`${guestRoleType}LabelDisplayed`] &&
        <div className={`${PROGRAM_CLASS}-${guestRoleType}-label`} style={{ color: "#64748B", fontWeight: 700 }}>
          {program[`${guestRoleType}Label`]}
        </div>
      }

      <div style={isClassWrapper ? { display: "flex", flexDirection: "row", flexWrap: "wrap" } : {}}>
        {guests.map(guest => {
          return <div key={guest.id} style={{ display: "flex", flexDirection: "row", paddingTop: "10px" }}>
            {renderGuestIllustration(guest.illustrationUrl, guestRoleType)}
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", fontSize: "12px", lineHeight: "15px", color: "#404040" }}>
              {isNameDisplayed && <span className={`${PROGRAM_CLASS}-guest-name`} style={{ fontWeight: 700 }}>{guest.name}</span>}
              {renderPositionCompany(guest, isPositionDisplayed, isCompanyNameDisplayed)}
            </div>
          </div>;
        })}
      </div>
    </div>;
  };

  const renderLiveButton = (): JSX.Element => {
    const { sessionLivePagePath } = settingsConfiguration;
    const { id, liveOver, hasReplay, speakers, liveOpenAt, minutesBeforeLiveAccess } = session;

    return <LiveAccessButton
      sessionLivePagePath={sessionLivePagePath}
      guestId={guestId}
      canAccessLive={canAccessLive()}
      sessionId={id}
      liveOver={liveOver}
      hasReplay={hasReplay}
      speakers={speakers}
      liveOpenAt={liveOpenAt}
      minutesBeforeLiveAccess={minutesBeforeLiveAccess}
      extraClasses="mt-10"
    />;
  };

  const renderRankedDisplayOptions = (): JSX.Element => {
    const { displayOptionsRanking } = program;
    if (displayOptionsRanking.length === 0) return null;

    const { traits } = session;
    const displayOptions = displayOptionsRanking.map(key => {
      if (["speakers", "exhibitors", "moderators"].includes(key)) {
        return renderGuestInformation(key as GuestRoleType);
      } else {
        const trait = traits.find(trait => trait.key === key);
        if (!trait) return null;

        return trait.value && <div key={trait.key} className="metadata-wrapper">
          {trait.labelDisplayed && <span style={{ color: "#64748B", fontWeight: 700 }}>{trait.label} : </span>}
          <span>{trait.value}</span>
        </div>;
      }
    });

    return <>{displayOptions}</>;
  };

  const renderRegistrationButton = (): JSX.Element => {
    const { enableUnknownGuestCustomButton } = settingsConfiguration;

    if (guestCanRegister()) {
      return <SessionRegistrationButton
        session={session}
        settingsConfiguration={settingsConfiguration}
        registered={registeredToSession()}
        capacityReached={sessionCapacityReached()}
        updateRegistered={setRegistered}
        updateRemainingSlots={updateRemainingSlots}
        showAddToCalendarModal={showAddToCalendarModal}
      />;
    } else if (enableUnknownGuestCustomButton) {
      return <GuestRegistrationButton
        locale={locale}
        settingsConfiguration={settingsConfiguration}
        guestStatus={guestStatus}
        guestId={guestId}
        multilingual={multilingual}
        guestRegistrationFormUrl={guestRegistrationFormUrl}
        guestConfirmationPageUrl={guestConfirmationPageUrl}
      />;
    } else {
      return null;
    }
  };

  const renderButtons = (): JSX.Element => {
    const { enableRegistration, enableUnknownGuestCustomButton } = settingsConfiguration;
    const physicalSessionFormat = session.format === "physical";

    if (!enableRegistration && physicalSessionFormat || !guestId && !enableUnknownGuestCustomButton) return null;

    return <div className="display-flex fd-row jc-space-between flex-wrap mt-10">
      {enableRegistration && renderRegistrationButton()}
      {!physicalSessionFormat && renderLiveButton()}
    </div>;
  };

  return <div className={sessionCardWidth}>
    <div ref={lastSessionElementRef} className="panel card-shadow card-border-radius" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "calc(100% - 30px)", backgroundColor: "#fff", marginTop: "15px", marginBottom: "15px", padding: "25px" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {renderDateTime()}
        {renderSessionName()}
        {renderLabels()}
        {renderDescription()}
        {renderLocation()}
        {renderRankedDisplayOptions()}
        {renderRemainingSlots()}
      </div>
      {renderButtons()}
    </div>
  </div>;
};

export default SessionCard;
