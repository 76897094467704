import { PRIMARY_COLOR } from "../../constants/Constants";

interface LoaderProps {
  size?: string;
  color?: string;
  inline?: boolean;
  withOverlay?: boolean;
  message?: string;
  containerHeight?: string;
}

const Loader = ({ size, color, inline, withOverlay, message, containerHeight }: LoaderProps): JSX.Element => {
  const spinner = <svg className={`spinner spinner-${size}`} viewBox="0 0 40 40" style={{ "stroke": color }}>
    <circle className="path" cx="20" cy="20" r="18" fill="none" strokeWidth="4"></circle>
  </svg>;

  if (inline)
    return spinner;

  const style = {};

  if (containerHeight)
    style["height"] = containerHeight;

  const loaderContent = <div className="loader-container" style={style}>
    <div className="loader-content text-center">
      { spinner }
      { message ? <p className="helper-text">{message}</p> : null }
    </div>
  </div>;

  if (withOverlay) {
    return <div className="loader-overlay">
      { loaderContent }
    </div>;
  }

  return loaderContent;
};

Loader.defaultProps = {
  color: PRIMARY_COLOR,
  inline: true,
  withOverlay: false,
  message: null,
  containerHeight: null,
  size: "medium"
};

export default Loader;
