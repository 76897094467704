import { useRef, useCallback, useState, useEffect, Fragment, createContext } from "react";
import Loader from "../components/shared/Loader.react";
import SessionCard from "./SessionCard.react";
import AddToMyCalendarModal from "./AddToMyCalendarModal.react";
import { ProgramSession, Program, SettingsProps } from "./types/Program";
import moment from "moment";
import momentIgnoreTimezone from "../themes/utils/momentIgnoreTimezone";
import TimeConflictChecker from "../time_conflict_checker/TimeConflictChecker";

export const TimeConflictCheckerContext = createContext(null);

const replaceWarningIcon = (node: HTMLElement, renderFn: any): void => {
  clearWarningIcon(node);
  injectWarningIcon(node, renderFn);
};

const injectWarningIcon = (node: HTMLElement, renderFn: any): void => {
  const span = document.createElement("span");
  span.classList.add("in-conflict-warning-icon");
  span.style.marginLeft = "10px";
  node.appendChild(span);
  renderFn(span);
};

const clearWarningIcon = (node: HTMLElement): void => {
  const span = node.querySelector(".in-conflict-warning-icon");
  span.parentElement.removeChild(span);
};

const disableSessionRegistrationButton = (node: HTMLElement): void => {
  const linkElement = node.querySelector("a");
  linkElement.classList.add("disabled");
};

const enableSessionRegistrationButton = (node: HTMLElement): void => {
  const linkElement = node.querySelector("a");
  linkElement.classList.remove("disabled");
};

interface Props {
  sessions: ProgramSession[];
  program: Program;
  guestStatus: string;
  settingsConfiguration: SettingsProps;
  filterArgs: any;
  hasNextPage: boolean;
  loading: boolean;
  searching: boolean;
  cursor: string;
  guestId: string;
  multilingual: boolean;
  guestRegistrationFormUrl: string;
  guestConfirmationPageUrl: string;
  datesOffset: number;
  locale: string;

  fetchMore: (cursor: string) => void;
}

const Sessions: React.FC<Props> = ({
  sessions,
  program,
  guestStatus,
  settingsConfiguration,
  filterArgs,
  hasNextPage,
  loading,
  searching,
  cursor,
  guestId,
  multilingual,
  guestRegistrationFormUrl,
  guestConfirmationPageUrl,
  datesOffset,
  locale,
  fetchMore,
}) => {
  const [sessionsByDay, setSessionsByDay] = useState({});
  const [selectedSession, setSelectedSession] = useState(null);
  const [isAddToCalendarModalOpen, setIsAddToCalendarModalOpen] = useState(false);

  const timeConflictChecker = useRef<TimeConflictChecker>(null);

  useEffect(() => {
    if (!program) return;

    updateSessionsByDay();
  }, [sessions]);

  useEffect(() => {
    if (Object.keys(sessionsByDay).length === 0) return;

    if (timeConflictChecker.current !== null) {
      timeConflictChecker.current.runCheck();
      return;
    }

    timeConflictChecker.current = window["eventmaker"].TimeConflictChecker({
      onConflict: (node: HTMLElement, conflicting: any[], mode: string, wasAlreadyConflicting: boolean, renderDefaultWarningIcon: any): void => {
        if (mode === "restrictive" && !wasAlreadyConflicting) {
          disableSessionRegistrationButton(node);
        }

        const fn = wasAlreadyConflicting ? replaceWarningIcon : injectWarningIcon;
        fn(node, renderDefaultWarningIcon);
      },
      onNoMoreConflict: (node: HTMLElement, mode: string): void => {
        if (mode === "restrictive") {
          enableSessionRegistrationButton(node);
        }
        clearWarningIcon(node);
      },
    });
  }, [sessionsByDay]);

  const updateSessionsByDay = (): void => {
    const res = sessions.reduce((acc, s) => {
      const key = moment(momentIgnoreTimezone(s.startDate).format("YYYY-MM-DD")).format("X"); // convert into Timestamp
      acc[key] ||= [];
      acc[key].push(s);
      return acc;
    }, {});
    setSessionsByDay(res);
  };

  const observer = useRef<IntersectionObserver>(null);

  const lastSessionElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchMore(cursor);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasNextPage, cursor]
  );

  const toggleAddToCalendarModal = (): void => {
    setIsAddToCalendarModalOpen(!isAddToCalendarModalOpen);
  };

  const renderSessionsByStartingDates = (sessions: ProgramSession[]): JSX.Element[] => {
    const sessionsByStartingDates = sessions.reduce((acc, s) => {
      const key = moment(momentIgnoreTimezone(s.startDate)).format("LT");
      acc[key] ||= [];
      acc[key].push(s);
      return acc;
    }, {});

    return Object.keys(sessionsByStartingDates).map(startingDate => {
      return <div key={startingDate}>
        <h5 style={{ paddingTop: "15px" }}>{startingDate}</h5>
        {renderSessionCards(sessionsByStartingDates[startingDate] as ProgramSession[])}
      </div>;
    });
  };

  const renderSessionCards = (sessions: ProgramSession[]): JSX.Element => {
    return <div className="row-flex">
      {sessions.map((session: ProgramSession) =>
        <SessionCard
          lastSessionElementRef={lastSessionElementRef}
          key={session.id}
          session={session}
          program={program}
          guestStatus={guestStatus}
          settingsConfiguration={settingsConfiguration}
          guestId={guestId}
          multilingual={multilingual}
          guestRegistrationFormUrl={guestRegistrationFormUrl}
          guestConfirmationPageUrl={guestConfirmationPageUrl}
          locale={locale}
          showAddToCalendarModal={(): void => {
            toggleAddToCalendarModal();
            setSelectedSession(session);
          }}
        />
      )}
    </div>;
  };

  const renderSessions = (): JSX.Element => {
    if (!program || sessions.length === 0) return;

    if (filterArgs.sort_by_recommendation) {
      return renderSessionCards(sessions as ProgramSession[]);
    }

    const { groupSessionsByStartingDates } = program;

    return <>
      {Object.keys(sessionsByDay).sort().map(day => {
        const formattedDate = moment.unix(Number(day)).format("dddd, LL");
        return <Fragment key={day} >
          <h4 className="date-separator" style={{ position: "sticky", top: datesOffset }}>
            {formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)}
          </h4>
          {groupSessionsByStartingDates ? renderSessionsByStartingDates(sessionsByDay[day] as ProgramSession[]) : renderSessionCards(sessionsByDay[day] as ProgramSession[])}
        </Fragment>;
      })}
    </>;
  };

  const renderSearchingLoader = (): JSX.Element => {
    if (!loading || !searching) return null;

    return <div className="text-center loader-sticky-container" style={{ "top": sessions.length === 0 ? "30px" : "50vh", "position": sessions.length === 0 ? "relative" : "sticky" }}>
      <div className="loader-card card-shadow card-border-radius">
        <Loader color={settingsConfiguration.primaryColor} />
      </div>
    </div>;
  };

  const renderFetchMoreLoader = (): JSX.Element => {
    if (!loading || searching) return null;

    return <div className="text-center">
      <div className="loader-card card-shadow card-border-radius">
        <Loader color={settingsConfiguration.primaryColor} />
      </div>
    </div>;
  };

  const renderNoResultsLabel = (): JSX.Element => {
    if (!program || sessions.length !== 0) return null;

    return <div className="panel panel-warning text-center mt-20" style={{ "opacity": searching ? 0.3 : 1 }}>
      <div className="panel-body">
        <p>{program.noResultsLabel}</p>
      </div>
    </div>;
  };

  // this button will only appear if the infinite scroll is broken due to a bug
  // that only happens if the list of filters displayed on the left is very long
  const renderSafetyFetchMoreButton = (): JSX.Element => {
    if (!hasNextPage || loading || searching) return;

    return <div className="text-center mt-20">
      <button className="btn btn-sm btn-secondary" onClick={(): void => fetchMore(cursor)}>
        <i className="fa-regular fa-angle-down"></i>
      </button>
    </div>;
  };

  return <div className="row-flex">
    {renderSearchingLoader()}
    <TimeConflictCheckerContext.Provider value={timeConflictChecker}>
      <div className="col-xs-12" style={{ "opacity": searching ? 0.3 : 1 }}>
        {renderSessions()}
      </div>
    </TimeConflictCheckerContext.Provider>
    <div className="col-xs-12">
      {renderFetchMoreLoader()}
      {renderNoResultsLabel()}
      {renderSafetyFetchMoreButton()}
    </div>
    <AddToMyCalendarModal session={selectedSession} isOpen={isAddToCalendarModalOpen} onClose={toggleAddToCalendarModal}/>
  </div>;
};

export default Sessions;
