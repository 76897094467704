import { gql } from "@apollo/client";

export const TOGGLE_SESSION_REGISTRATION = gql`
  mutation ToggleSessionRegistration($accesspointId: ID!, $active: Boolean!) {
    toggleSessionRegistration(input: { accesspointId: $accesspointId, active: $active }) {
      clientMutationId
      accessPrivileges {
        accesspointId
      }
      paymentInfo {
        cartItems {
          priceInclVat
          label
          accesspointId
          quantity
          isCheckedOut
        }
        paymentTotalExclTaxes
        paymentTotalInclTaxes
        currency
        balance
        currentCartExclTaxes
        currentCartInclTaxes
        discountAmount
        discountAmountExclTaxes
        discountPercentage
        displayDiscountWithoutTaxes
        paymentMerchantType
        isMultiCart
        pricesAfterDiscount
      }
      errors
    }
  }
`;
