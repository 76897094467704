export function mergeArrays<T>(arr1: T[], arr2: T[], idKey: string): T[] {
  const existing = arr1.reduce((acc, item) => {
    return { ...acc, [item[idKey]]: true };
  }, {});

  const next = [...arr1];

  arr2.forEach(item => {
    if (existing[item[idKey]]) return;

    next.push(item);
  });
  return next;
}
