import { nbFiltersSelected } from "./utils/filtersCount";
import { Program } from "./types/Program";

interface Props {
  filterArgs: any;
  currentProgram: Program;

  toggleFilterPane: () => void;
}

const FiltersPaneButton: React.FC<Props> = ({ filterArgs, currentProgram, toggleFilterPane }) => {
  const nbFilters = nbFiltersSelected(currentProgram, filterArgs);

  return <button className="button button-small filters-pane-button" onClick={toggleFilterPane}>
    <i className="fa-regular fa-filter" aria-hidden="true"></i>
    <span className="hidden-xs"> {I18n.t("front_office.react.programs.filters")}</span>
    <span> {nbFilters > 0 && `(${nbFilters})`}</span>
  </button>;
};

export default FiltersPaneButton;
