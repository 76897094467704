import { ThematicType, ThematicsDisplayLevel } from "../types/Thematic";
import { DEFAULT_THEMATICS_COLOR } from "../../constants/Constants";

interface Props {
  classNames: string;
  style: any;
  thematics: ThematicType[];
  thematicsDisplayLevel?: ThematicsDisplayLevel;
  coloredThematics: boolean;
}

const Thematics: React.FC<Props> = ({
  classNames,
  style,
  thematics,
  thematicsDisplayLevel,
  coloredThematics,
}) => {
  if (thematics.length === 0) return null;

  return <div className={classNames} style={style}>
    {thematics.reduce((acc, thematic) => {
      if (thematicsDisplayLevel === "highest" && thematic.parentThematicId) {
        return acc;
      } else if (thematicsDisplayLevel === "lowest" && thematic.hasChildren) {
        return acc;
      }
      acc.push(<div key={thematic.name}>
        <span className="label" style={{ backgroundColor: coloredThematics ? thematic.color : DEFAULT_THEMATICS_COLOR }}>{thematic.name}</span>
      </div>);
      return acc;
    }, [])}
  </div>;
};

export default Thematics;
