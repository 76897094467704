import { ProgramFilter } from "./types/Program";
import SessionTypeFilter from "./filters/SessionTypeFilter.react";
import AttendanceTypeFilter from "./filters/AttendanceTypeFilter.react";
import SessionRoomFilter from "./filters/SessionRoomFilter.react";
import DateRangeFilter from "./filters/DateRangeFilter.react";
import ThematicFilter from "./filters/ThematicFilter.react";
import TraitFilter from "./filters/TraitFilter.react";
import BookmarksOnlyFilter from "./filters/BookmarksOnlyFilter.react";
import MissedSessionsFilter from "./filters/MissedSessionsFilter.react";
import SortByRecommendationFilter from "./filters/SortByRecommendationFilter.react";
import { refreshValuesOfUrlParam } from "./utils/urlParams";
import { BOOLEAN_PROGRAM_FILTER_KEYS } from "../constants/Constants";

const FILTER_COMPONENTS_MAPPING = {
  "session_type": SessionTypeFilter,
  "attendance_type": AttendanceTypeFilter,
  "session_room": SessionRoomFilter,
  "date_range": DateRangeFilter,
  "thematic": ThematicFilter,
  "trait": TraitFilter,
  "bookmarks_only": BookmarksOnlyFilter,
  "missed_sessions": MissedSessionsFilter,
  "sort_by_recommendation": SortByRecommendationFilter,
};

interface Props {
  locale: string;
  filters: ProgramFilter[];
  filterArgs: any;
  coloredThematics: boolean;
  navigationByDateEnabled: boolean;

  searchSessions: (filterArgs: any) => void;
}

const Filters: React.FC<Props> = ({ locale, filters, filterArgs, coloredThematics, navigationByDateEnabled, searchSessions }) => {

  const sortedFilters = (): ProgramFilter[] => {
    return [...filters].sort((a, b) => a.rank - b.rank);
  };

  const updateFilterArgs = (filterKey: string, newValues: any): void => {
    let valuesInUrl = newValues;
    if (BOOLEAN_PROGRAM_FILTER_KEYS.includes(filterKey)) {
      valuesInUrl = (newValues === null) ? [] : [newValues];
    }
    refreshValuesOfUrlParam(filterKey, valuesInUrl);
    if (newValues === null || newValues.length === 0) {
      const newFilterArgs = { ...filterArgs };
      delete newFilterArgs[filterKey];
      searchSessions(newFilterArgs);
    } else {
      searchSessions({ ...filterArgs, [filterKey]: newValues });
    }
  };

  const renderFilter = (filter: ProgramFilter, keyIndex: number): JSX.Element => {
    const FilterComponent = FILTER_COMPONENTS_MAPPING[filter.type];
    return <FilterComponent
      key={`filter-component-${keyIndex}`}
      locale={locale}
      filter={filter}
      filterArgs={filterArgs}
      coloredThematics={coloredThematics}
      navigationByDateEnabled={navigationByDateEnabled}
      updateFilterArgs={updateFilterArgs}
      searchSessions={searchSessions}
    />;
  };

  return <>
    {sortedFilters().map((filter, index) => renderFilter(filter, index))}
  </>;
};

export default Filters;
