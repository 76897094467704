
interface Props {
  target: any;
  subject: string;
  buttonClass?: string;
  style?: "basic"|"inline";
}

const CLASS_BUTTON = "btn btn-default btn-sm btn-block";

const dateToString = (date): string => {
  // return this format: YYYYMMDDTHHmmssZ
  return (new Date(date).toISOString()).replace(/-|:|\.\d+/g, "");
};

const removeTags = (description): string => {
  return description.replace(/(<([^>]+)>)/ig, "");
};

const AddToMyCalendarButtons: React.FC<Props> = ({ target, subject, buttonClass, style }) => {
  const buttonClassName = buttonClass?.length > 0 ? buttonClass : CLASS_BUTTON;

  const ics = (label): JSX.Element => {
    if (!target) return null;

    const url = `data:text/calendar;charset=utf8,${[
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "BEGIN:VEVENT",
      `URL:${encodeURIComponent(document.URL)}`,
      `DTSTART:${encodeURIComponent(dateToString(target.startDate))}`,
      `DTEND:${encodeURIComponent(dateToString(target.endDate))}`,
      `SUMMARY:${encodeURIComponent(subject)}`,
      `DESCRIPTION:${encodeURIComponent(removeTags(target.description) || "")}`,
      `LOCATION:${encodeURIComponent(target.location?.name || "")}`,
      "STATUS:CONFIRMED",
      "SEQUENCE:0",
      "END:VEVENT",
      "END:VCALENDAR",
    ].join("%0D%0A")}`;

    const isMobile = /iPhone|Android|webOS|iPod|BlackBerry|IEMobile/i.test(navigator.userAgent);
    const targetValue = isMobile ? "self" : "_blank";

    return <a className={`${label}-btn ${buttonClassName}`} target={targetValue} href={url}>{label} Calendar</a>;
  };

  const renderGoogleButton = (): JSX.Element => {
    if (!target) return null;

    const details = [
      document.URL,
      removeTags(target.description)
    ].filter(s => s).join("\n");

    const url = [
      "https://www.google.com/calendar/render",
      "?action=TEMPLATE",
      `&text=${encodeURIComponent(subject)}`,
      `&dates=${encodeURIComponent(dateToString(target.startDate))}`,
      `/${encodeURIComponent(dateToString(target.endDate))}`,
      `&details=${encodeURIComponent(details)}`,
      `&location=${encodeURIComponent(target.location?.name || "")}`,
      "&sprop=&sprop=name:",
    ].join("");

    return <a href={url} className={`google-btn ${buttonClassName}`} target="_blank">Google Calendar</a>;
  };

  const renderYahooButton = (): JSX.Element => {
    if (!target) return null;

    const startDate = new Date(target.startDate);
    const endDate = new Date(target.endDate);
    const timezone = startDate.getTimezoneOffset() / 60;

    // Remove timezone to the initial date
    startDate.setHours(startDate.getHours() - timezone);
    endDate.setHours(endDate.getHours() - timezone);

    const url = [
      "http://calendar.yahoo.com/?v=60&view=d&type=20",
      `&title=${encodeURIComponent(subject)}`,
      `&st=${encodeURIComponent(dateToString(startDate))}`,
      `&et=${encodeURIComponent(dateToString(endDate))}`,
      `&desc=${encodeURIComponent(removeTags(target.description) || "")}`,
      `&in_loc=${encodeURIComponent(target.location?.name || "")}`,
    ].join("");

    return <a href={url} className={`yahoo-btn ${buttonClassName}`} target="_blank">Yahoo! Calendar</a>;
  };


  const renderOutlookButton = (): JSX.Element => {
    return ics("Outlook");
  };

  const renderIcalButton = (): JSX.Element => {
    return ics("iCal");
  };

  if (style === "inline") {
    return <>
      {renderGoogleButton()}
      {renderYahooButton()}
      {renderOutlookButton()}
      {renderIcalButton()}
    </>;
  }

  return <div className="display-flex fd-col">
    <div className="display-flex mb-10">
      {renderGoogleButton()}
      {renderYahooButton()}
    </div>
    <div className="display-flex">
      {renderOutlookButton()}
      {renderIcalButton()}
    </div>
  </div>;
};

export default AddToMyCalendarButtons;
